.page-index {

    @include breakpoint(980px) {

        hr.category-posts {
            display: none;
        }

    }

    #right-sidebar {

        @include breakpoint(970px) {

            .widget-facebook {
                display: none;
            }

        }

        @include breakpoint(810px) {

            .widget-search {
                display: none;
            }

            .widget-advertisment {
                margin-top: 20px;
            }

        }

        @include breakpoint(680px) {
            display: none;
        }

        .widget-categories {
            display: none !important;
        }

    }

    #page-index-carousel {
        max-width: 590px;
        margin-bottom: 0;

        .carousel-control {
            background: none;
            border: none;
            top: 50%;

            @include breakpoint(1025px) {
                display: none;
            }

        }

        .carousel-indicators {
            top: auto;
            bottom: 0;
            left: 0;
            right: auto;
            z-index: 5;
            margin: 0;
            list-style: none;

            @include breakpoint(1025px) {
                display: none;
            }

            .active {
                background-color: #C9C9C9;
            }

            li {
                width: 112px;
                height: 7px;
                margin-left: 5px;
                text-indent: -999px;
                background-color: #4C82BC;
                border-radius: 0;
            }

        }

        .item span {
            position: absolute;
            padding: 20px 30px;
            background: #4C82BC;
            color: white;
            bottom: 30px;
            left: 0;
            display: table;
            width: 95%;
            max-width: 500px;
            font-size: 18px;
            line-height: 1.5;
            opacity: 0.95;
            box-sizing: border-box;

            @include breakpoint(1025px) {
                bottom: 10px;
                font-size: 15px;
                padding: 10px;
            }

        }

    }

    .category-main-post {
        background: #3F75B6;
        border-bottom: 3px solid #3F75B6;

        &:hover {
            background: #4C82BC;
        }

        h4 {
            font-weight: normal;
            line-height: 1.5;

            @include breakpoint(520px) {
                font-size: 15px;
            }

        }

        .outerContainer {
            display: table;
            height: 130px;
            overflow: hidden;
            /* width: 240px; */
            margin: 0 auto;
            padding: 0 20px;

            @include breakpoint(520px) {
                height: auto;
                padding: 10px 20px;
            }

            .innerContainer {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                margin: 0 auto;
                text-align: left;
            }

        }

        a {
            color: white;
            text-decoration: none;

            img {

                &.mobile {
                    display: none;

                    @include breakpoint(520px) {
                        display: block;
                    }

                }

                &.pull-left {

                    @include breakpoint(520px) {
                        display: none;
                    }

                }

            }

        }

    }

    .category-posts {

        @include breakpoint(850px) {
            margin-bottom: -40px;
        }

        hr {

            @include breakpoint(850px) {
                display: none;
            }

        }

    }

    .index-main-half {

        @include breakpoint(850px) {
            margin: 0 0 40px 0;
            width: 100%;
            padding: 0 30px !important;
            box-sizing: border-box;
        }

        &.left-position {
            padding-left: 30px;
        }

        &.right-position {
            padding-right: 30px;
        }

        ul {
            margin: 20px 0 -10px 20px;

            @include breakpoint(850px) {
                margin-left: 15px;
            }

            li {
                list-style-type: none;
                list-style-position: outside;
                padding-left: 10px;
                position: relative;
                margin-bottom: 10px;
                font-size: 15px;

                &:before {
                    content: "\00BB";
                    color: #3F75B6;
                    position: absolute;
                    left: -10px;
                }

                a {
                    color: #3F75B6;
                }

            }

        }


    }

}