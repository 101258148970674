.widget-search {
    border-top: 3px solid #4D82BC;

    .s-search {
        border-left: 1px solid #DFDFDF;
        border-right: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF;
        margin: 0;
        padding: 0;
        height: 38px;
        margin-bottom: 20px;
        position: relative;
    }

    form {
        margin: 0;
        padding: 0;
        @include clearfix;
    }

    #searchbox {
        float: left;
        width: 100%;;
        height: 38px;
        padding: 0 15px;
        box-sizing: border-box;
        border: 0 !important;
        border-radius: 0;
        box-shadow: 0 0 0 0;
        position: relative;
    }

    #searchbut {
        background: white;
        font-weight: bold;
        border: 0;
        width: 40px;
        height: 38px;
        color: black;
        cursor: pointer;
        border-left: 1px solid #DFDFDF;
        background: url('../../img/vyhledavac.png') top left no-repeat;
        background-position: 10px 12px;
        position: absolute;
        right: 0;
        top: 0;
    }

}