.page-404 {
    text-align: center;

    i {
        font-size: 300px;
        color: #4C82BC;
        margin: 20px 0 40px 0;
        display: inline-block;

        @include breakpoint(500px) {
            font-size: 150px;
        }

    }

}