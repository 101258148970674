.credentials {
    margin: 20px 32px 0 32px;
    font-size: 12px;

    @include breakpoint(680px) {
        margin: 20px 20px;
    }

    span.credentials-item {

        @include breakpoint(680px) {
            float: left !important;

            &:last-of-type {
                margin-top: 10px;
            }

        }

        &:first-of-type {
            float: left;
        }

        &:last-of-type {
            float: right;
        }

        a {
            color: #454545;
            text-decoration: underline;

            &:hover {
                color: #8f8f8f;
            }

        }

    }

}