#navigation {
    margin: 0 0 30px 0;
    padding: 0 0 0 0;
    border-bottom: 1px solid #DFDFDF;
    font-size: 0;
    width: 100%;
    display: table;

    @include breakpoint(880px) {
        display: none;
    }

    li {
        display: table-cell;
        width: auto;
        text-align: center;
        list-style-type: none;
        list-style-position: inside;
        margin: 0 0 0 0;
        border-right: 1px solid #DFDFDF;
        font-size: 17px;

        &:last-of-type {
            border-right: 1px solid transparent;
        }

        &:hover {
            background: #4C82BC;

            &:last-of-type {
                background: #4C82BC;
            }

            a {
                color: white;
                text-decoration: none;

                i {
                    color: white;
                }

            }

        }

        a {
            color: #212121;
            text-decoration: none;
            display: inline-block;
            height: 100%;
            width: 100%;
            padding: 17px 0;

            i {
                padding-right: 10px;
                font-size: 16px;
                color: #aeaeae;
            }

        }

        .active {
            background: #4C82BC;

            a {
                color: white;
                text-decoration: none;

                i {
                    color: white;
                }

            }

        }

    }

}