.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter, div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

a img {

    &.alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    &.alignnone {
        margin: 5px 20px 20px 0;
    }

    &.alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    &.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}

.wp-caption {
    border: 1px solid #D7D7D7;
    text-align: center;
    background-color: #FCFCFC;
    padding: 10px 5px;
    box-sizing: border-box;
    max-width: 100%;

    img {
        max-width: 100%;
        margin: 0;
        padding: 0;
        border: 0 none;
    }

    p.wp-caption-text {
        font-size: 12px;
        line-height: 17px;
        margin: 10px 5px 0 5px;
    }

}

.gallery {
    margin: auto;
}

.gallery-item {
    float: left;
    margin-top: 10px;
    text-align: center;
    width: 30%;
}

.gallery img {
    border: 1px solid #D7D7D7 !important;
    padding: 10px;
}

.gallery-caption {
    margin-left: 0;
}