.widget-archiv {
    position: relative;
    margin-bottom: 20px;

    select {
        background: transparent url('../../img/archiv-arrow.png') bottom right no-repeat;
        width: 100%;
        font-size: 16px;
        border-radius: 0;
        -webkit-appearance: none;
        line-height: 1;
        padding-left: 20px;
        border: 1px solid #DFDFDF;
        height: 43px;
    }

    .tlacitko {
        width: 45px;
        height: 40px;
        background: #4D82BC url('../../img/select-arrow.png') top left no-repeat;
        background-position: 14px 15px;
        position: absolute;
        right: 0;
        top: 0;
        border-bottom: 3px solid #3969A5;
    }

}