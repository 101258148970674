.template-page-author {

    .grid {
        margin-bottom: -10px;

        .grid-item {
            width: 50%;
            width: calc(50% - 10px);
            margin-bottom: 10px;
            position: relative;
            opacity: 0;

            .link-to-image {
                width: 30px;
                height: 30px;
                position: absolute;
                left: 15px;
                bottom: 15px;
                background-image: url("../../img/icon-news.png");
                background-size: 30px 30px;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }

                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                }

            }

        }

    }

}