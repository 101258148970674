.archive-title {
    color: #4C82BC;
    font-size: 22px !important;
    margin: 0 0 20px 0 !important;
    padding: 0 0 10px 0 !important;
    border-bottom: 1px solid #DFDFDF;
    display: block;
    text-transform: uppercase;
    font-weight: normal !important;

    &.rubriky-index {
        margin: -10px 0 5px 0 !important;
        padding: 0 0 0 0 !important;
        border-bottom: none;
        text-decoration: none;

        i {
            font-size: 20px;
            padding-right: 10px;
            color: #b9b9b9;
        }

        a {
            color: #4C82BC;
            text-decoration: none;
        }

    }

}