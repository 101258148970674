.page-single {

    .first-section-below-post {
        padding-top: 10px !important;
    }

    .sharing-services {
        margin-bottom: 25px;
    }

    .next-posts-item {
        width: 24%;
        float: left;
        margin-right: 1%;

        img {
            width: 100%;
        }

        img.mobile {
            display: none;
        }

        @include breakpoint(970px) {
            width: 32%;

            &:nth-last-child(-n+1) {
                display: none;
            }

            img.desktop {
                display: none;
            }

            img.mobile {
                display: block;
            }

        }

        @include breakpoint(850px) {
            width: 49%;

            &:nth-last-child(-n+2) {
                display: none;
            }

        }

        @include breakpoint(500px) {
            width: 100%;
            float: none;
            margin-right: 0;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

        }

        a {
            color: #212121;
            text-decoration: none;

            &:hover {
                color: white;
            }

        }

        span {
            background: white;
            display: block;
            padding: 10px;
        }

        &:hover span {
            background: #3F75B6;
        }

        @include breakpoint(500px) {

            span {
                background: #3F75B6;
            }

            a {
                color: white;
            }

        }

    }

    .tags-title {
        padding-top: 25px !important;
    }

    .tags a {
        display: inline-block;
        margin-right: 5px;
        padding: 7px 13px;
        color: #212121;
        background: #F2F2F2;
        margin-bottom: 5px;

        &:hover {
            background: #3F75B6;
            color: white;
            text-decoration: none;
        }

    }

    .share-icon {
        font-size: 35px;
        margin-right: 10px;
        display: inline-block;
        text-decoration: none;
        margin-top: -10px;
        margin-bottom: 20px;

        &.facebook {
            color: #3C599B;
            text-decoration: none;
        }

        &.twitter {
            color: #5CD7FC;
            text-decoration: none;
        }

        &.google {
            color: #D8012F;
            text-decoration: none;
        }

    }

    .author-box-single {
        margin-top: 10px;
        margin-bottom: 10px;

        @include breakpoint(500px) {
            text-align: center;
        }

        &.video {
            border-top-color: #00696B;

            a {
                color: #00696B !important;
                text-decoration: underline;
            }

        }

        &.writing,
        &.none {
            border-color: #0063A0;

            a {
                color: #0063a0;
                text-decoration: underline;
            }

        }

        &.pr {
            border-color: #D96A1B;

            a {
                color: #D96A1B;
                text-decoration: underline;
            }

        }

        span {
            display: inline-block;
            font-size: 16px;
            margin-bottom: 5px;
            font-weight: bold;
            line-height: 1;
            margin-top: 3px;
        }

        img {
            width: 70px;
            float: left;
            margin-right: 20px;

            @include breakpoint(500px) {
                float: none;
                @include border-radius(50%);
                display: block;
                margin: 0 auto;
                margin-bottom: 20px;
            }

        }

        p {
            margin-bottom: 0;
        }

    }

    iframe {
        width: 100%;
        overflow: hidden;
    }

}