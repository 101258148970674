.widget-categories {
    display: none;

    @include breakpoint(880px) {
        display: block;
    }

    ul {
        margin-left: 5px;
        padding: 0;
        @include clearfix();
        margin-bottom: -10px;

        li {
            display: block;
            list-style: none;
            margin-left: 0;
            width: 50%;
            float: left;

            a {
                color: #212121;
                text-decoration: none;
                display: inline-block;
                height: 100%;
                width: 100%;
                padding: 5px 0;

                i {
                    padding-right: 10px;
                    font-size: 16px;
                    color: #aeaeae;
                }

            }

        }

    }

}