#right-sidebar {
    width: 100%;
    max-width: 360px;
    float: right;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;

    @include breakpoint(750px) {
        max-width: none;
    }

    @include breakpoint(750px) {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px dashed #DFDFDF;
    }

    @include breakpoint(500px) {
        padding: 0 15px;
        padding-top: 30px;
    }

}