$media-query-responsive: 1100px;
$media-query-tablet: 780px;
$media-query-mobile: 505px;

@mixin breakpoint($media) {
	@if $media == responsive {
		@media only screen and (max-width: $media-query-responsive) { @content; }
	}
	@else if $media == tablet {
		@media only screen and (max-width: $media-query-tablet) { @content; }
	}
	@else if $media == mobile {
		@media only screen and (max-width: $media-query-mobile) { @content; }
	}
    @else {
		@media only screen and (max-width: $media) { @content; }
	}
}