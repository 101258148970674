.widget-box {
    border-top: 3px solid #4C82BC;
    margin-bottom: 30px;

    .inner {
        border-left: 1px solid #DFDFDF;
        border-right: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF;
        padding: 20px;
    }

    .widget-title {
        font-size: 22px;
        margin: 0 0 15px 0;
        padding: 0 0 10px 0;
        border-bottom: 1px solid #DFDFDF;
        display: block;
        text-transform: uppercase;
        color: #4C82BC;
    }

}