.page-author {

    .author-box {
        padding: 15px;
        border-top: 6px solid #4C82BC;
        border-bottom: 2px solid #4C82BC;
        border-left: 2px solid #4C82BC;
        border-right: 2px solid #4C82BC;

        @include breakpoint(450px) {
            text-align: center;
        }

        h1 {
            margin-bottom: 5px;
            margin-top: 0;
            line-height: 1;
        }

        .description {
            display: block;
            margin-bottom: 10px;
        }

        img {
            padding-right: 5px;
            float: left;

            @include breakpoint(450px) {
                @include border-radius(50%);
                float: none;
                margin-bottom: 15px;
            }

        }

        .title {
            font-size: 20px;
            text-transform: uppercase;
            color: #4C82BC;
            line-height: 1.5;
            margin-bottom: 10px;
        }

    }

    .author-archive-line {
        margin: 22px 0 !important;
    }

}