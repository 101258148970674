.page-content {

    h1 {
        font-size: 25px;
        color: #4C82BC;
        margin: 0 0 15px 0;
        padding: 0 0 0 0;
        font-weight: normal;
        line-height: 1.3;
    }

    .date2 {
        font-size: 12px;
        color: #999999;
        margin-bottom: 15px;

        span {
            margin-right: 20px;

            @include breakpoint(500px) {
                margin-right: 0;
                display: block;
                margin: 5px 0;
            }

        }

        a {
            color: #999999;
            text-decoration: underline;
        }

        i {
            font-size: 12px;
            margin-right: 8px;
            color: #999999;
        }

    }

    h2 {
        font-size: 18px;
        color: #212121;
        margin: 0 0 0 0;
        padding: 10px 0 5px 0;
        line-height: 1.3;
    }

    h3 {
        font-size: 14px;
        color: #212121;
        margin: 0 0 0 0;
        padding: 10px 0 5px 0;
        line-height: 1.3;
    }

    ul, ol {
        margin-bottom: 20px;
    }

    ul li, ol li {
        margin-bottom: 5px;
    }

    .excerpt {
        font-weight: 600;
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .featured-image {
        margin-top: 5px;
    }

    .popis-fotky {
        font-size: 12px;
        color: #999999;
        float: left;
        display: inline-block;
        margin-top: 8px;
        margin-bottom: 20px;
    }

    .widget-infobox {
        padding: 20px;
        float: right;
        display: inline-block;
        border: 1px solid #D7D7D7;
        width: 160px;
        margin-left: 20px;
        margin-bottom: 20px;
        background: #fcfcfc;
        margin-top: 10px;
        font-size: 13px;

        @include breakpoint(470px) {
            width: 100%;
            box-sizing: border-box;
        }

        span {
            display: block;
            border-bottom: 1px solid #D7D7D7;
            padding-bottom: 10px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }

    }

    .post-author {
        font-size: 12px;
        color: #999999;
        margin-top: 10px;
        display: inline-block;
    }

    blockquote {
        border-left: 5px solid #4A7FC1;
        margin-left: 10px;
        font-size: 16px;

        p {
            line-height: 1.5;
        }

    }

}