html {
    font-size: 62.5%; /* sets the base font to 10px for easier math (rems) 1rem = 10 px */
}

body {
    @include font-size(14);
    padding: 50px 0 30px 0;
    background: #F2F2F2;
    font-family: 'Titillium Web', sans-serif;
    color: #212121;
    line-height: 1.5;

    @include breakpoint(480px) {
        padding: 30px 0;
    }

}

strong {
    font-weight: 600;
}

p {
    margin-bottom: 15px;
}

a {
    color: #D01F25;
    text-decoration: none;

    &:hover {
        color: #bc272c;
        text-decoration: underline;
    }

}

.wrapper {
    width: 100%;
    max-width: 1010px;
    min-width: 375px;
    margin: 0 auto;
    overflow: hidden;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    box-sizing: border-box;
}

#logo {
    background: url('../../img/logo.png') top left no-repeat;
    width: 282px;
    height: 60px;

    h1 {
        display: none;
    }

    @include breakpoint(480px) {
        width: 211px;
        height: 45px;
        background-size: 211px 45px;
        position: relative;
        left: 10px;
    }

}

#description {
    @include clearfix;

    a {
        margin-left: 15px;
        font-size: 18px;
        color: black;
        text-decoration: none !important;

        @include breakpoint(480px) {
            display: block;
            font-size: 15px;
            margin-bottom: 30px;
        }

    }

}

#logo-vosp {
    width: 80px;
    height: 80px;
    background: url('../../img/logo-vosp.png') no-repeat;
    margin-bottom: 30px;

    @include breakpoint(480px) {
        display: none;
    }

}

.theme-container {
    background: white;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 30px;

    @include breakpoint(880px) {
        padding-top: 30px;
    }

    @include breakpoint(500px) {
        padding: 15px 0;
    }

}

.theme-container-outer {
    border-top: 3px solid #4C82BC;
    width: 100%;
}

.full-width {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;

    @include breakpoint(500px) {
        padding: 0 15px;
    }

}

#left-sidebar {
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 360px;
    margin-right: -360px;
    float: left;

    @include breakpoint(750px) {
        margin-right: 0;
        padding-right: 30px;
        float: none;
    }

    @include breakpoint(500px) {
        padding: 0 15px;
    }

}

hr {
    border-color: #C9C9C9 -moz-use-text-color #FFFFFF;
    margin: 30px 0;
}