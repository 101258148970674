.page-template-authors {

    h1 {
        text-align: center;
        font-size: 50px;

        @include breakpoint(600px) {
            font-size: 40px;
        }

        @include breakpoint(440px) {
            font-size: 30px;
        }

    }

    hr {
        margin-top: 30px;
        margin-bottom: 0;
    }

    h2 {
        font-size: 35px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;
        font-weight: 400;

        @include breakpoint(600px) {
            font-size: 30px;
            margin-top: 20px;
            margin-bottom: 30px;
        }

        @include breakpoint(440px) {
            font-size: 25px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

    }

    .authors-items {
        @include clearfix;

        .item {
            text-align: center;
            width: 19%;
            margin-right: 1%;
            float: left;
            margin-bottom: 40px;

            @include breakpoint(930px) {
                width: 24%;
            }

            @include breakpoint(770px) {
                width: 32%;
            }

            @include breakpoint(610px) {
                width: 49%;
            }

            @include breakpoint(450px) {

                a {
                    font-size: 15px;
                }

                img {
                    width: 50%;
                }

            }

            &:hover {

                a {
                    color: #CA1C22;
                    text-decoration: none;
                }

            }

            a {
                color: black;
                font-size: 17px;

                img {
                    @include border-radius(50%);
                    margin-bottom: 15px;
                }

            }

        }

    }

}