.widget-advertisment {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px auto;
    text-align: center;

    img {
        width: 100%;
        max-width: 300px;
        height: auto;
    }

}