.page-archive {

    .article {
        padding: 15px;
        border-top: 3px solid #C9C9C9;
        border-bottom: 1px solid #C9C9C9;
        border-left: 1px solid #C9C9C9;
        border-right: 1px solid #C9C9C9;

        &:hover {
            border-top: 3px solid #4C82BC;
            border-bottom: 1px solid #4C82BC;
            border-left: 1px solid #4C82BC;
            border-right: 1px solid #4C82BC;
        }

        a {
            position: relative;

            img {
                width: 170px;
                margin-right: 15px;

                @include breakpoint(930px) {
                    width: 120px;
                }

                @include breakpoint(850px) {
                    width: 100% !important;
                    margin-right: 0;
                    margin-bottom: 15px;
                }

            }

        }

        .media-body {

            @include breakpoint(850px) {
                float: none;
                width: 100%;
            }

            h2 {
                font-size: 20px;
                line-height: 1.3;
                margin: 0 0 5px 0;
                font-weight: normal;
                padding: 0 0 0 0;

                @include breakpoint(850px) {
                    font-size: 16px;
                }

                a {
                    color: #212121;
                    text-decoration: none;

                    &:hover {
                        color: #4A7FC1;
                    }

                }

            }

            .excerpt {

                @include breakpoint(850px) {
                    display: none;
                }

            }

        }

        .post-author {
            margin: 0 0 5px 0;
            font-size: 12px;
            color: #999999;
            display: inline-block;
        }

        p {
            margin-bottom: 0;
        }

    }

    .archiv-pagination {
        margin-top: 20px;
    }

}