.widget-most-viewed-posts {

    img {
        padding-right: 5px;
    }

    .media-body a {
        color: #212121;
        text-decoration: none;
        &:hover {
            color: #4C82BC;
        }

    }

}